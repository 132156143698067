<template>
  <div class="col-lg-10 col-lg-offset-1">
    <RequestLoader v-if="!job" />
    <section v-if="job">
      <div class="jumbotron">
        <h1>{{ job.puesto }}</h1>
        <p v-if="job.horario">
          <span class="vertical-date">
            Horario: <small>{{ job.horario }}</small>
          </span>
        </p>
        <p>
          <router-link
            class="btn btn-primary btn-lg"
            :to="{
              name: 'register',
              params: {
                puesto: job.puesto,
                estado: job.estado || '',
                zona: job.zona || '',
              },
            }"
            >Aplicar</router-link
          >
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <dl class="dl-horizontal">
            <dt v-if="job.estado">Estado</dt>
            <dd>
              {{ job.estado }}
            </dd>
            <dt v-if="job.zona">Zona</dt>
            <dd>
              {{ job.zona }}
            </dd>
          </dl>
        </div>
        <div class="col-lg-6"></div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <dl class="dl-horizontal">
            <dt v-if="job.conocimientos && job.conocimientos.length > 0">Conocimientos</dt>
            <dd v-for="(conocimiento, index) in job.conocimientos" :key="index">
              {{ conocimiento }}
            </dd>
            <dt v-if="job.requisitos && job.requisitos.length > 0">Requisitos</dt>
            <dd v-for="(requisito, index) in job.requisitos" :key="index">
              {{ requisito }}
            </dd>
          </dl>
        </div>
        <div class="col-lg-6">
          <dl class="dl-horizontal">
            <dt v-if="job.funciones && job.funciones.length > 0">Funciones</dt>
            <dd v-for="(funcion, index) in job.funciones" :key="index">
              {{ funcion }}
            </dd>
            <dt v-if="job.prestaciones && job.prestaciones.length > 0">Prestaciones</dt>
            <dd v-for="(prestacion, index) in job.prestaciones" :key="index">
              {{ prestacion }}
            </dd>
          </dl>
        </div>
      </div>
    </section>
    <section v-if="error">
      <div class="alert alert-info" role="alert">
        {{ error }}
        <router-link class="alert-link" :to="{ name: 'jobs' }"
          >Ver el catálogo</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "../Firebase";
import RequestLoader from "../components/RequestLoader.vue";
export default {
  name: "LayoutJobsDetail",
  data() {
    return {
      job: null,
      key: null,
      error: null,
    };
  },
  created() {
    var db = firebase.firestore();

    const ref = db.collection("jobs").doc(this.$route.params.jobId);
    ref.get().then((doc) => {
      if (doc.exists) {
        this.key = doc.id;
        this.job = doc.data();
      } else {
        this.error = "La vacante ya no se encuentra disponible. ";
      }
    });
  },
  components: {
    RequestLoader,
  },
};
</script>

<style scoped>
.jumbotron {
  background-color: var(--accyon-carousel-color-primary);
  color: ivory;

  padding: 20px;
}
</style>

<template>
  <div class="loading">
    <flower-spinner :animation-duration="2500" :size="170" color="#1ab394" />
  </div>
</template>

<script>
import { FlowerSpinner } from "epic-spinners";
export default {
  name:"RequestLoader",
  components: {
    FlowerSpinner,
  },
};
</script>

<style scoped>
.loading {
  position: relative;
  z-index: 500;
  height: 170px;
  padding-top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-margin {
  min-height: 170px;
}

.flower-spinner{
  margin:0 auto;
}
</style>
